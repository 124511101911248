






































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { toNumber } from '@amcharts/amcharts4/.internal/core/utils/Type';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import DialogComponent from '@/components/dialog.component.vue';
import { APPIDS, EXPORT_EXAM, QUESION_TYPES } from '../../enums';
import {
  QuestionTypeOptions,
  QuestionsTypeTotalCounts,
  Exam,
  QuestionTypeCount,
} from '../../types/exam.type';
import QuestionsDetails from '../QuestionsDetails.component.vue';

@Component({
  components: {
    QuestionsDetails,
    DialogComponent,
  },
})
export default class AddQuestions extends Vue {
  @Prop() examBasicData!: Exam;

  @Prop(String) readonly examStatus!: string;

  @Prop(Boolean) readonly editExam!: boolean;

  appId: string = APPIDS.EXAM_GENERATION;

  courseId: string = this.$route?.params.courseId;

  choosenQuestionId = '';

  questionTypeOptions: QuestionTypeOptions[] = [];

  questionsCount = 0;

  questionsTypeCounts: QuestionsTypeTotalCounts = {};

  nQuestions = '';

  nAnswers = '';

  validForm = false;

  questionsList: QuestionTypeCount[] =
    this.examBasicData?.examInfo?.questionTypesCounts ?? [];

  rules = this.getRules();

  loading = true;

  totalInputQuestions = 0;

  $refs!: {
    QuestionsDetailsCell: QuestionsDetails;
    form: HTMLFormElement;
  };

  getRules() {
    return {
      nQuestions: [
        (val: number) => !!val
          || this.$t('validations.REQUIRED_FIELD', {
            fieldName: this.$t('labels.COUNT_OF_QUESTIONS'),
          }),
        (val: number) => !(toNumber(val) === 0) || this.$t('validations.NOT_EQUAL_ZERO'),
        (val: number) => !(val < 0) || this.$t('validations.NEGATIVE_NUMBER'),
        (val: number) => !(val > this.getMaxQuestionsAllowed())
          || this.$t(
            this.getMaxQuestionsAllowed() > 0
              ? 'validations.MAX_NUMBER_QUESTIONS'
              : 'validations.NOT_ALLOWED_QUESTION_TYPE',
            {
              max: this.getMaxQuestionsAllowed(),
            },
          ),
        (val) => /^[1-9]\d*$|^$/.test(val) || this.$t('validations.NO_FRACTION'),
      ],
      questionType: [
        (v) => !!v
          || this.$t('validations.REQUIRED_FIELD', {
            fieldName: this.$t('labels.questionType'),
          }),
      ],
      numAnswers: [
        (v) => !!v
          || this.$t('validations.REQUIRED_FIELD', {
            fieldName: this.$t('labels.COUNT_OF_ANSWERS_OPTIONS'),
          }),
      ],
    };
  }

  getMaxQuestionsAllowed(): number {
    if (
      [QUESION_TYPES.MCQ.id, QUESION_TYPES.MMCQ.id].includes(this.choosenQuestionId)
      && this.nAnswers
    ) {
      return this.questionsTypeCounts
        && this.questionsTypeCounts[this.choosenQuestionId]
        && this.questionsTypeCounts[this.choosenQuestionId][this.nAnswers]
        ? this.questionsTypeCounts[this.choosenQuestionId][this.nAnswers]
        : 0;
    }
    if (this.choosenQuestionId) {
      return this.questionsTypeCounts
        && this.questionsTypeCounts[this.choosenQuestionId]
        ? this.questionsTypeCounts[this.choosenQuestionId].total
        : 0;
    }
    return this.questionsCount;
  }

  @Watch('choosenQuestionId')
  @Watch('nAnswers')
  refreshValidations() {
    if (this.nQuestions) {
      (this.$refs.form as HTMLFormElement & { validate: () => boolean }).validate();
    }
  }

  async mounted() {
    await this.loadFilters();
    this.countNumberOfQuestions();
  }

  showQuestionsDetails() {
    this.$refs.QuestionsDetailsCell.open();
  }

  async loadFilters() {
    const { questionTypeOptions, questionsTypeCount, questionsCount } = await ExamGenerationProvider.getAddEditQuestion(
      this.appId,
      this.courseId,
      this.examBasicData.examInfo.examLang,
      this.examBasicData.examInfo.training,
    );
    this.questionsCount = questionsCount as number;
    this.questionsTypeCounts = questionsTypeCount as QuestionsTypeTotalCounts;
    this.questionTypeOptions = questionTypeOptions as QuestionTypeOptions[];
    this.questionTypeOptions = this.questionTypeOptions?.map(
      (type: QuestionTypeOptions) => ({
        ...type,
        translatedName: this.$options.filters?.servertranslate(type.baName),
      })
    );
    this.loading = false;
  }

  countNumberOfQuestions() {
    if (this.examBasicData.examInfo.questionTypesCounts.length) {
      this.examBasicData.examInfo.questionTypesCounts.forEach((ques) => {
        this.totalInputQuestions += ques.questionCount;
      });
    }
  }

  get headers() {
    return [
      {
        text: this.$t('tableHeaders.questionType'),
        sortable: false,
        align: 'center',
        value: 'questionType',
        class: 'grey darken-4 white--text pa-3 qb-table__header',
      },
      {
        text: this.$t('tableHeaders.questionNumber'),
        sortable: false,
        value: 'nQuestions',
        align: 'center',
        class: 'grey darken-4 white--text pa-3 qb-table__header',
      },
      {
        text: this.$t('tableHeaders.COUNT_OF_ANSWERS'),
        sortable: false,
        value: 'nAnswers',
        align: 'center',
        class: 'grey darken-4 white--text pa-3 qb-table__header',
      },
      {
        sortable: false,
        align: 'center',
        value: 'actions',
        class: 'grey darken-4 white--text pa-3 qb-table__header',
        width: '150px',
      },
    ];
  }

  addQuestionToTable() {
    (
      this.$refs.form as HTMLFormElement & { validate: () => boolean }
    ).validate();
    if (this.validForm) {
      if (!this.showNumAnswers) {
        this.nAnswers = '';
      }
      this.questionsList.push({
        questionType: this.choosenQuestionId,
        questionCount: toNumber(this.nQuestions),
        answersCount: this.nAnswers ? toNumber(this.nAnswers) : null,
      });
      this.totalInputQuestions += toNumber(this.nQuestions);
      (this.$refs.form as HTMLFormElement & { reset: () => boolean }).reset();
      if (this.examBasicData) {
        this.examBasicData.examInfo.questionTypesCounts = this
          .questionsList as QuestionTypeCount[];
      }
    }
  }

  get showNumAnswers() {
    return Boolean(
      this.choosenQuestionId === QUESION_TYPES.MCQ.id
        || this.choosenQuestionId === QUESION_TYPES.MMCQ.id,
    );
  }

  get isNumQuestionsZero() {
    return this.questionsCount;
  }

  getQuestionName(questionType) {
    return this.questionTypeOptions?.find((item) => item.baID === questionType)
      ?.baName;
  }

  deleteQuestion(item) {
    this.totalInputQuestions -= item.questionCount;
    this.questionsList.splice(this.questionsList.indexOf(item), 1);
    if (this.examBasicData) {
      this.examBasicData.examInfo.questionTypesCounts = this
        .questionsList as QuestionTypeCount[];
    }
  }

  get totalQuestionsValidation() {
    return this.totalInputQuestions > this.questionsCount;
  }

  validate() {
    return !this.totalQuestionsValidation;
  }

  get canEdit() {
    return this.examStatus !== EXPORT_EXAM.NO_MODEL && this.editExam;
  }
}
