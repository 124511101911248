
















































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import * as he from 'html-entities';
import RangeDifficulty from '@/shared/RangeDifficulty.component.vue';
import {
  ExamDropInput,
  IloIdInput,
  ExamAddfiltersTypes,
  Exam,
  SubjectIput,
  InstructorAutoComplete,
} from '../../types/exam.type';
import { APPIDS, EXPORT_EXAM, MODELS_MATCH } from '../../enums';

@Component({
  components: { RangeDifficulty },
})
export default class ExamBsicdata extends Vue {
  @Prop() readonly value!: number[];

  @Prop(Boolean) readonly isSCU!: boolean;

  @Prop(String) readonly courseId!: string;

  @Prop() readonly filtersData!: ExamAddfiltersTypes;

  @Prop() readonly examBasicData!: Exam;

  @Prop(String) readonly examStatus!: string;

  @Prop(Boolean) readonly editExam!: boolean;

  testExam = this.examBasicData.examInfo.totalDegree;

  degreeStatus = false;

  plusDegrees = false;

  experimental = false;

  totalDegrees = this.testExam;

  addDegreesDisable = false;

  displayDateFrom = false;

  ShowRange = true;

  displayDateTo = false;

  maxDifficulty: number | undefined = this.examBasicData.examInfo.maxDifficulty;

  currentDate = new Date().toISOString();

  appId = APPIDS.EXAM_GENERATION;

  $refs!: {
    form: HTMLFormElement;
    degreesInputRef: HTMLFormElement;
  };

  changeVal(val) {
    let a = 0;
    let b = 0;
    [a, b] = val;

    this.examBasicData.examInfo.minDifficulty = a;
    this.examBasicData.examInfo.maxDifficulty = b;
  }

  get rangeDifficulty() {
    this.maxDifficulty = 100 - this.examBasicData.examInfo.maxDifficulty;
    return [
      this.examBasicData?.examInfo.minDifficulty,
      this.examBasicData?.examInfo.maxDifficulty,
    ];
  }

  get formattedDateTo() {
    if (new Date(this.examBasicData.examInfo.examDate) < new Date()) {
      return this.formatDate(this.currentDate.split('T')[0]);
    }
    return this.formatDate(this.examBasicData.examInfo?.examDate);
  }

  set formattedDateTo(val) {
    if (this.examBasicData.examInfo) {
      this.examBasicData.examInfo.examDate = this.formatDate(val, true);
    }
  }

  selectedAssessmentDegree() {
    const examTypeOption = this.filtersData.examTypeOptions.find(
      (option) => option.baID === this.examBasicData.examInfo.examType,
    );
    if (examTypeOption) {
      if (examTypeOption.degree === 0) {
        this.totalDegrees = this.testExam as number;
        this.examBasicData.examInfo.totalDegree = this.totalDegrees;
        this.experimental = true;
        this.degreeStatus = false;
      } else {
        this.totalDegrees = examTypeOption?.degree as number;
        this.experimental = false;
        if (this.plusDegrees) {
          this.degreeStatus = false;
        } else {
          this.degreeStatus = true;
          this.examBasicData.examInfo.totalDegree = this.totalDegrees;
        }
      }
    }
  }

  selectedPlusDegree() {
    this.plusDegrees = this.examBasicData.examInfo.Degree;
    this.$refs.degreesInputRef.validate();
    this.selectedAssessmentDegree();
  }

  selectedMatchOption() {
    if (this.examBasicData.examInfo.modelsMatch === MODELS_MATCH.NOT_MATCHING) {
      this.examBasicData.examInfo.Degree = false;
      this.addDegreesDisable = true;
    } else {
      this.addDegreesDisable = false;
    }
    this.selectedPlusDegree();
  }

  formatDate(date, reverse = false) {
    if (!date) return '';

    if (reverse) {
      const [year, month, day] = date.split('/');
      return `${year}-${month}-${day}`;
    }
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  nameRules = [
    (v: string) => !!v || this.$t('requiredField'),
    (v: string) => (v && v.length <= 100) || `${this.$t('maxLetters')} 100 `,
    (v: string) => (v && v.length >= 5) || `${this.$t('minLetters')} 5 `,
  ];

  DateRules = [(v: string) => !!v || this.$t('requiredField')];

  SelectRules = [(v: number) => !!v || this.$t('requiredField')];

  timeRules = [
    (v: number) => !!v || this.$t('requiredField'),
    (v: number) => (v && v >= 1) || `${this.$t('minValue')} 1 `,
    (v: number) => (v && v <= 1000) || `${this.$t('maxValue')} 1000 `,
  ];

  examSignatureRule = [
    (v: string) => (v || '').length < 50 || `${this.$t('maxLetters')} 50 `,
  ];

  examPeriodRules = [
    (v: number) => (v < 10 && v > 0) || this.$t('validations.periodPattern'),
    (v: number) => /^[0-9]{1}$/.test(`${v}`) || this.$t('validations.periodPattern'),
  ];

  get courseCodeRule() {
    return this.isSCU
      ? [(val: string) => /^[0-9]{2}$/.test(val) || this.$t('validations.codePattern')]
      : [(v: string) => (v || '').length < 50 || `${this.$t('maxLetters')} 50 `];
  }

  get DegreeRules() {
    return [
      (v: number) => !!v || this.$t('requiredField'),
      (v: number) => (v && v >= 0.01) || `${this.$t('minValue')} 0.01 `,
      (v: number) => (v && v <= 1000) || `${this.$t('maxValue')}  1000 `,
      (v: number) => !this.plusDegrees
        || this.examBasicData.examInfo.examType === ''
        || this.experimental
        || (!!v && v < this.totalDegrees)
        || `${this.$t('maxLessValue')} ${this.totalDegrees} `,
      (v: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(v) || this.$t('validations.maxFloating'),
    ];
  }

  get exposureLimitRules() {
    return [
      (v: number) => (v === null || v >= 1) || `${this.$t('minValue')} 1 `,
      (v: number) => (!v || v <= 999) || `${this.$t('maxValue')}  999 `,
      (v: number) => (!v || Number(v) === Math.round(v)) || this.$t('validations.integerValue'),
    ];
  }

  instructorNamrRule = [
    (v) => v.length <= 100 || `${this.$t('maxValue')} 100 `,
    (v) => /^[A-Za-z\u0621-\u064A_. ]*$/.test(v)
      || /.+@.+\..+/.test(v)
      || this.$t('invalidInputData'),
  ];

  get currentLocale() {
    return this.$store.state.lang.lang;
  }

  get filters(): ExamAddfiltersTypes {
    return {
      academicYears: this.filtersData.academicYears?.map(
        (academicYear: ExamDropInput) => ({
          ...academicYear,
          id: academicYear.baID,
          translatedName: this.$options.filters?.servertranslate(
            academicYear.baName
          ),
        })
      ),
      courseCode: this.filtersData.courseCode,
      questionsCount: this.filtersData.questionsCount,
      difficultyOptions: this.filtersData.difficultyOptions?.map(
        (difficultyOption: ExamDropInput) => ({
          ...difficultyOption,
          id: difficultyOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            difficultyOption.baName
          ),
        })
      ),
      examTypeOptions: this.filtersData.examTypeOptions?.map(
        (examTypeOption: ExamDropInput) => ({
          degree: examTypeOption.degree,
          id: examTypeOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            examTypeOption.baName
          ),
        })
      ),
      ilosCounts: this.filtersData.ilosCounts?.map((ilosCount: IloIdInput) => ({
        ...ilosCount,
        id: ilosCount.iloId,
        translatedName: this.$options.filters?.servertranslate(
          ilosCount.iloName
        ),
      })),
      langOptions: this.filtersData.langOptions?.map(
        (langOption: ExamDropInput) => ({
          ...langOption,
          id: langOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            langOption.baName
          ),
        })
      ),
      modelsMatchOptions: this.filtersData.modelsMatchOptions?.map(
        (modelsMatchOption: ExamDropInput) => ({
          ...modelsMatchOption,
          id: modelsMatchOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            modelsMatchOption.baName
          ),
        })
      ),
      questionTypeOptions: this.filtersData.questionTypeOptions?.map(
        (questionTypeOption: ExamDropInput) => ({
          ...questionTypeOption,
          id: questionTypeOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            questionTypeOption.baName
          ),
        })
      ),
      shuffleOptions: this.filtersData.shuffleOptions?.map(
        (shuffleOption: ExamDropInput) => ({
          ...shuffleOption,
          id: shuffleOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            shuffleOption.baName
          ),
        })
      ),
      subjects: this.filtersData.subjects?.map((subject: SubjectIput) => ({
        ...subject,
        id: subject.subjectID,
        translatedName: subject.subjectName,
      })),
      termOptions: this.filtersData.termOptions?.map(
        (termOption: ExamDropInput) => ({
          ...termOption,
          id: termOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            termOption.baName
          ),
        })
      ),
      turnOptions: this.filtersData.turnOptions?.map(
        (turnOption: ExamDropInput) => ({
          ...turnOption,
          id: turnOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            turnOption.baName
          ),
        })
      ),
      trainingOptions: this.filtersData.trainingOptions?.map(
        (trainingOption: ExamDropInput) => ({
          ...trainingOption,
          id: trainingOption.baID,
          translatedName: this.$options.filters?.servertranslate(
            trainingOption.baName
          ),
        })
      ),
      instructors: this.filtersData.instructors?.map(
        (instructor: InstructorAutoComplete) => ({
          ...instructor,
          id: instructor.ID,
          translatedName: this.$options.filters?.servertranslate(
            instructor.Name
          ),
        })
      ),
    };
  }

  get canEdit() {
    return this.examStatus !== EXPORT_EXAM.NO_MODEL && this.editExam;
  }

  validate() {
    return this.$refs.form.validate();
  }

  mounted() {
    if (this.isSCU) {
      this.examBasicData.examInfo.modelsMatch = MODELS_MATCH.PERFECT_MATCH;
    }
    // if (this.editExam) {
    //   const currentDate = new Date();
    //   const year = currentDate.getFullYear();
    //   const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    //   const day = String(currentDate.getDate()).padStart(2, '0');
    //   this.examBasicData.examInfo.examDate = `${year}-${month}-${day}`;
    // }
    this.examBasicData.examInfo.examTitle = he.decode(
      this.examBasicData.examInfo.examTitle,
    );
    this.selectedMatchOption();
  }
}
