



































































































































































































































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import DialogComponent from '@/components/dialog.component.vue';
import { APPIDS, EXPORT_EXAM, FLOATING_DIGITS } from '../../enums';
import QuestionsDetails from '../QuestionsDetails.component.vue';
import {
  Exam,
  ExamMatrixRecord,
  SubjectData,
  AddFilters,
  IloIdInput,
} from '../../types/exam.type';

@Component({
  components: {
    DialogComponent,
    QuestionsDetails,
  },
})
export default class ExamMatrixComponent extends Vue {
  @Prop({ default: [] }) previousExamMatrix!: ExamMatrixRecord[];

  @Prop({
    default: () => ({}),
  })
  filters!: AddFilters;

  @Prop({ default: {} }) examData!: Exam;

  @Prop(String) readonly examStatus!: string;

  @Prop(Boolean) readonly editExam!: boolean;

  @Prop(Boolean) readonly isFlexibleILOs!: boolean;

  questionMatrix: number[][] = [];

  degreesMatrix: number[][] = [];

  subjectData: SubjectData[] = [];

  ilosData: IloIdInput[] = [];

  userRights = [];

  submitted = false;

  showModal = false;

  validForm = false;

  examId = '';

  courseId = '';

  appId = APPIDS.EXAM_GENERATION;

  headerClass = 'white--text pa-3 qb-table__header text-center';

  floatingDigits = FLOATING_DIGITS

  $refs!: {
    matrixForm: HTMLFormElement;
    QuestionsDetailsCell: QuestionsDetails;
  };

  BasicRules = [
    (v: number) => !!v || v === 0 || this.$t('requiredField'),
    (v: number) => (v && v >= 0) || v === 0 || `${this.$t('minValue')} 0 `,
  ];

  LecturesCountRules = [
    ...this.BasicRules,
    (v: number) => (v && v <= 50) || v === 0 || `${this.$t('maxValue')} 50 `,
  ];

  QuestionsCountRules = [
    ...this.BasicRules,
    (v: number) => (v && v <= this.getTotalQuestions())
      || v === 0
      || `${this.$t('maxValue')} ${this.getTotalQuestions()}`,
    (v: number) => (v && Number.isInteger(v)) || v === 0 || `${this.$t('integarInvalid')}`,
  ];

  DegreesCountRules = [
    ...this.BasicRules,
    (v: string) => /^\d{0,3}(?:\.\d{1,2})?$/.test(v)
        || this.$t('validations.maxFloating'),
    (v: number) => (Number(v && Number(v).toFixed(this.floatingDigits)) <= Number(Number(this.getTotalDegrees()).toFixed(this.floatingDigits)))
      || v === 0
      || `${this.$t('maxValue')} ${this.getTotalDegrees()} `,
  ];

  ilosWeightsRules = [
    ...this.BasicRules,
    (v: number) => (v && v <= 100) || v === 0 || `${this.$t('maxValue')} 100 `,
  ];

  mounted() {
    this.examId = this.$route.params.examId;
    this.courseId = this.$route.params.courseId;
    this.ilosData = this.filters.ilosCounts;
    this.setInitialState();
  }

  @Watch('isValid')
  onValidationChange(newVal) {
    this.$emit('matrixValidationChange', newVal);
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get isValid() {
    return this.questionTypes.length
      && this.validForm && (this.formErrors.length === 0);
  }

  get allSubjectsSelected() {
    return !this.subjectData.some((subject) => !subject.selected);
  }

  get formErrors() {
    const errors: string[] = [];
    if (
      this.totalCurrentDegrees !== Number(this.totalDegrees)
      && this.totalCurrentDegrees !== 0
    ) {
      errors.push('invalidDegreesTotal');
    }
    if (
      this.totalCurrentQuestions !== Number(this.totalQuestions)
      && this.totalCurrentQuestions !== 0
    ) {
      errors.push('invalidQuestionsTotal');
    }
    for (let i = 0; i < this.questionMatrix.length; i += 1) {
      for (let j = 0; j < this.questionMatrix[i].length; j += 1) {
        if (this.questionMatrix[i][j] === 0 && this.degreesMatrix[i][j] !== 0) {
          errors.push('zeroQuestion');
        } else if (
          this.degreesMatrix[i][j] === 0
          && this.questionMatrix[i][j] !== 0
        ) {
          errors.push('zeroDegree');
        }
      }
    }
    if (
      this.iloSelectedZeroError
      && (this.totalCurrentDegrees !== 0 || this.totalCurrentQuestions !== 0)
    ) {
      errors.push('iloSelectedZero');
    }
    return errors;
  }

  get iloSelectedZeroError() {
    return this.ilosData.some((item) => item.selected && item.weight === 0);
  }

  get mainHeaders() {
    return [
      {
        text: this.$t('tableHeaders.subject'),
        rowspan: 2,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionsAndDegrees'),
        rowspan: 2,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.ILOs'),
        colspan: this.ilosData.length,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionsCount'),
        rowspan: 2,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.degreesSum'),
        rowspan: 2,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.relativeWeights'),
        rowspan: 2,
        class: this.headerClass,
      },
    ];
  }

  get totalDegrees() {
    return this.examData.examInfo.totalDegree;
  }

  get totalQuestions() {
    return this.examData.examInfo.questionTypesCounts.reduce(
      (acc, item) => item.questionCount + acc,
      0,
    );
  }

  get questionTypes() {
    return (this.examData.examInfo.questionTypesCounts.map((qtyCount) => (
      {
        ...qtyCount,
        answersCount:
        qtyCount.answersCount === 0
        && (qtyCount.questionType === '3.4.' || qtyCount.questionType === '3.1.')
          ? null : qtyCount.answersCount,
      }))
    );
  }

  getTotalQuestions() {
    return this.totalQuestions;
  }

  getTotalDegrees() {
    return this.totalDegrees;
  }

  get isNewMatrix() {
    return this.previousExamMatrix.length === 0;
  }

  get totalCurrentQuestions() {
    return this.questionMatrix.reduce((rowAcc, row) => {
      rowAcc += row.reduce(
        (colAcc, item) => colAcc + this.parseNumber(item),
        0,
      );
      return rowAcc;
    }, 0);
  }

  get totalCurrentDegrees() {
    return this.degreesMatrix.reduce((rowAcc, row) => {
      rowAcc += row.reduce(
        (colAcc, item) => colAcc + this.parseNumber(item),
        0,
      );
      return rowAcc;
    }, 0);
  }

  get selectedIlos() {
    return this.ilosData ? this.ilosData.filter((ilo) => ilo.selected) : [];
  }

  toggleSelectAllSubjects(checked) {
    const zeroSubjectRow = this.ilosData.map(() => 0);

    if (!checked) {
      this.questionMatrix = [];
      this.degreesMatrix = [];
    }
    this.subjectData.forEach((subject, subjectIndex) => {
      subject.selected = checked;
      if (!checked) {
        subject.lectureCount = 0;
        subject.weight = 0;
        this.questionMatrix[subjectIndex] = [...zeroSubjectRow];
        this.degreesMatrix[subjectIndex] = [...zeroSubjectRow];
      }
    });
  }

  setInitialState() {
    this.questionMatrix = this.calculateInitialQuestionsMatrix();
    this.degreesMatrix = this.calculateInitialDegreesMatrix();
    this.subjectData = this.calculateInitialSubjectData();
    this.ilosData = this.calculateInitialIlosData();
    if (this.isNewMatrix) {
      this.handleLectureCountChange();
    }
    this.$nextTick(() => {
      this.$refs.matrixForm.validate();
    });
  }

  getTotalRowQuestionCount(rowIdx) {
    return this.questionMatrix[rowIdx].reduce(
      (acc, item) => acc + this.parseNumber(item),
      0,
    );
  }

  getTotalRowDegreesCount(rowIdx) {
    return this.degreesMatrix[rowIdx].reduce(
      (acc, item) => acc + this.parseNumber(item),
      0,
    );
  }

  getTotalColQuestionCount(colIdx) {
    return this.questionMatrix.reduce(
      (acc, item) => acc + this.parseNumber(item[colIdx]),
      0,
    );
  }

  getTotalColDegreesCount(colIdx) {
    return this.degreesMatrix.reduce(
      (acc, item) => acc + this.parseNumber(item[colIdx]),
      0,
    );
  }

  calculateInitialSubjectData() {
    const totalMatrixLectureCount = this.previousExamMatrix.reduce(
      (acc, item) => acc + item.lectureCount,
      0,
    );
    const totalDefaultLectureCount = this.filters.subjects.reduce(
      (acc, item) => acc + Number(item.lectureCount),
      0,
    );
    return this.filters.subjects.map((subject) => {
      const matrixSubject = this.previousExamMatrix.find(
        (item) => Number(item.subject) === subject.subjectID,
      );
      const selected = this.isNewMatrix || !!matrixSubject;
      let lectureCount = 0;
      let weight = 0;
      if (matrixSubject) {
        lectureCount = Number(matrixSubject.lectureCount);
      } else if (this.isNewMatrix) {
        lectureCount = Number(subject.lectureCount);
      }
      if (matrixSubject) {
        weight = (Number(matrixSubject.lectureCount) || 0) / (totalMatrixLectureCount || 1);
      } else if (this.isNewMatrix) {
        weight = (Number(subject.lectureCount) || 0) / (totalDefaultLectureCount || 1);
      }

      return {
        id: subject.subjectID,
        name: subject.subjectName,
        selected,
        lectureCount,
        weight,
      };
    });
  }

  getSelectedIlosObj() {
    if (this.isNewMatrix) {
      return this.filters.ilosCounts.reduce((iloAcc, ilo) => {
        iloAcc[ilo.iloId] = { id: ilo.iloId };
        return iloAcc;
      }, {});
    }
    return this.previousExamMatrix[0].ilos.reduce((iloAcc, ilo) => {
      iloAcc[ilo.id] = { id: ilo.id, totalWeight: ilo.totalWeight };
      return iloAcc;
    }, {});
  }

  calculateInitialIlosData() {
    const ilosObj = this.getSelectedIlosObj();
    const totalIlosCount = this.filters.ilosCounts.reduce(
      (acc, iloItem) => acc + (ilosObj[iloItem.iloId] ? iloItem.ilosCount : 0),
      0,
    );
    return this.filters.ilosCounts.map((iloItem) => {
      let weight = 0;
      if (ilosObj[iloItem.iloId]) {
        weight = ilosObj[iloItem.iloId].totalWeight
          ? ilosObj[iloItem.iloId].totalWeight
          : iloItem.ilosCount / totalIlosCount;
      } else {
        weight = 0;
      }
      return {
        ...iloItem,
        weight: Number((weight * 100).toFixed(2)),
        selected: !!ilosObj[iloItem.iloId],
      };
    });
  }

  calculateInitialQuestionsMatrix() {
    return this.filters.subjects.map((subject) => {
      const matrixSubject = this.previousExamMatrix.find(
        (item) => Number(item.subject) === subject.subjectID,
      );
      return this.filters.ilosCounts.map((iloItem) => {
        if (!matrixSubject) {
          return 0;
        }
        const matrixIlo = matrixSubject.ilos.find(
          (item) => item.id === iloItem.iloId,
        );
        return matrixIlo ? Number(matrixIlo.questions) : 0;
      });
    });
  }

  calculateInitialDegreesMatrix() {
    return this.filters.subjects.map((subject) => {
      const matrixSubject = this.previousExamMatrix.find(
        (item) => Number(item.subject) === subject.subjectID,
      );
      return this.filters.ilosCounts.map((iloItem) => {
        if (!matrixSubject) {
          return 0;
        }
        const matrixIlo = matrixSubject.ilos.find(
          (item) => item.id === iloItem.iloId,
        );
        return matrixIlo ? Number(matrixIlo.degrees) : 0;
      });
    });
  }

  onLectureCountChange(subjectIdx, newValue) {
    this.subjectData[subjectIdx].lectureCount = newValue;
    this.handleLectureCountChange();
  }

  handleLectureCountChange() {
    this.setSubjectWeights();
    this.setQuestionCounts();
    this.setDegreeCounts();
  }

  parseNumber(stringVal) {
    const number = Number(stringVal);
    return Number.isNaN(number) ? 0 : number;
  }

  onSubjectCheckChange(subjectIdx, newValue) {
    this.subjectData[subjectIdx].selected = newValue;
    if (!newValue) {
      this.subjectData[subjectIdx].lectureCount = 0;
    }
    this.setSubjectWeights();
    this.setQuestionCounts();
    this.setDegreeCounts();
  }

  setSubjectWeights() {
    const totalLecturesCount = this.subjectData.reduce(
      (acc, item) => acc + this.parseNumber(item.lectureCount),
      0,
    );
    this.subjectData = this.subjectData.map((item) => {
      const lectureCount = this.parseNumber(item.lectureCount);
      const weight = lectureCount / totalLecturesCount;
      return {
        ...item,
        weight: Number.isNaN(weight) ? 0 : weight,
      };
    });
  }

  setQuestionCounts() {
    this.questionMatrix = this.subjectData
      .map((subjectItem) => this.ilosData.map((iloItem) => Number(
        (
          subjectItem.weight
            * (iloItem.weight / 100)
            * this.totalQuestions
        ).toFixed(2),
      )));
  }

  setDegreeCounts() {
    this.degreesMatrix = this.subjectData
      .map((subjectItem) => this.ilosData.map((iloItem) => Number(
        (
          subjectItem.weight
          * (iloItem.weight / 100)
          * this.totalDegrees
        ).toFixed(2),
      )));
  }

  onIloCheckChange(iloIdx, newValue) {
    this.ilosData[iloIdx].selected = newValue;
    this.setIloWeights();
    this.setQuestionCounts();
    this.setDegreeCounts();
  }

  setIloWeights() {
    const totalIlosCount = this.ilosData.reduce(
      (acc, item) => (acc += item.selected ? item.ilosCount : 0),
      0,
    );
    this.ilosData = this.ilosData.map((iloItem) => ({
      ...iloItem,
      weight: iloItem.selected
        ? Number(((iloItem.ilosCount / totalIlosCount) * 100).toFixed(2))
        : 0,
    }));
  }

  resetQuestionsAndDegrees() {
    this.setInitialState();
  }

  automaticRound() {
    this.questionMatrix = this.questionMatrix
      .map((row) => row.map((item) => Math.round(this.parseNumber(item))));
    this.degreesMatrix = this.degreesMatrix
      .map((row, rowIdx) => row.map((item, colIdx) => (this.questionMatrix[rowIdx][colIdx] === 0
        ? 0
        : Math.round(this.parseNumber(item)))));
  }

  getMatrix() {
    if (this.totalCurrentDegrees && this.totalCurrentQuestions && this.questionTypes.length) {
      return {
        questionMatrix: this.questionMatrix,
        degreesMatrix: this.degreesMatrix,
        subjectData: this.subjectData.map((subject) => ({
          id: subject.id,
          selected: subject.selected,
          lectureCount: subject.lectureCount,
        })),
        ilosData: this.ilosData.map((ilo) => ({
          iloID: ilo.iloId,
          selected: ilo.selected,
          weight: ilo.weight / 100,
        })),
      };
    }
    return {};
  }

  iloWeightChange(iloIdx) {
    if (
      Number(this.ilosData[iloIdx].weight) === 0
      || this.ilosData[iloIdx].weight > 100
    ) {
      return;
    }
    this.ilosData[iloIdx].weight = Number(
      this.ilosData[iloIdx].weight.toFixed(2),
    );
    this.setIlosWeightsBasedOnInput(iloIdx);
    this.setQuestionCounts();
    this.setDegreeCounts();
  }

  setIlosWeightsBasedOnInput(iloIdx) {
    const remainingIlosTotalWieghtsRatio = 100 - this.ilosData[iloIdx].weight;
    const remainingSelectedIlosCount = this.ilosData
      .filter((iloItem) => iloItem.selected).length - 1;
    const singleIloRatio = Number(
      (remainingIlosTotalWieghtsRatio / remainingSelectedIlosCount).toFixed(2),
    );
    this.ilosData = this.ilosData.map((iloItem, index) => {
      if (iloIdx === index) {
        return { ...iloItem };
      }
      return {
        ...iloItem,
        weight: iloItem.selected ? singleIloRatio : 0,
      };
    });
  }

  getSubjIloInfo(subject, ilo) {
    this.$refs.QuestionsDetailsCell.open(subject, ilo);
  }

  closeModal() {
    this.showModal = false;
  }

  get canEdit() {
    return this.examStatus !== EXPORT_EXAM.NO_MODEL && this.editExam;
  }
}
