













































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import {
  CourseQuestionBasic,
} from '../types/exam.type';
import { QUESION_TYPES } from '../enums';

@Component({
  components: {

  },
})
export default class QuestionsDetails extends Vue {
    @Prop() readonly appId!: string;

    @Prop() readonly courseId!: string;

    @Prop({ default: '' }) readonly langId!: string;

    @Prop({ default: '' }) readonly trainingId!: string;

    subject!: string;

    ilo!: string;

    showDialog = false;

    @Prop({ default: false }) readonly persistent!: boolean;

    @Prop({ default: '600px' }) readonly width!: boolean;

    @Prop({ default: false }) readonly fullscreen!: boolean;

    @Prop({ default: ' dialog-transition' }) readonly transition!: boolean;

    @Prop({ default: 'blue-grey' }) readonly color = 'blue-grey';

    headerClass = 'white--text pa-6 qb-table__header text-center';

    columnClass = 'pa-3 text-center';

    courseQuestions: CourseQuestionBasic[] = [];

    totalQuestions = 0;

    totalDegrees = 0;

    showDetails = false;

    get mainHeaders() {
      return [
        {
          text: this.$t('tableHeaders.questionType'),
          rowspan: 3,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.questionsCount'),
          colspan: 3,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.degreesMid'),
          rowspan: 3,
          class: this.headerClass,
        },
        {
          text: this.$t('tableHeaders.timeMid'),
          rowspan: 3,
          class: this.headerClass,
        },
      ];
    }

    async loadCourseQuestions(subjectId, iloId) {
      const questions = await ExamGenerationProvider
        .getCourseQuestions(this.appId, this.courseId, this.langId, this.trainingId, subjectId, iloId);
      this.courseQuestions = questions.questions as CourseQuestionBasic[];
      this.calcTotalDegreeQuestion();
    }

    calcTotalDegreeQuestion() {
      const { totalDegrees, totalQuestions } = Object
        .values(this.courseQuestions as CourseQuestionBasic).reduce((acc, obj) => {
          acc.totalQuestions += obj.questions ?? 0;
          acc.totalDegrees += obj.degrees ?? 0;
          return acc;
        }, { totalDegrees: 0, totalQuestions: 0 });
      this.totalDegrees = Math.round(totalDegrees * 100) / 100;
      this.totalQuestions = totalQuestions;
      this.showDetails = this.totalQuestions === 0;
    }

    get NumOfToalaQuestions() {
      return this.totalQuestions;
    }

    get NumOfTotalDegrees() {
      return this.totalDegrees;
    }

    get HaveAnsCount() {
      return Object.keys(this.courseQuestions)
        .includes(QUESION_TYPES.MCQ.id ?? '')
            || Object.keys(this.courseQuestions)
              .includes(QUESION_TYPES?.MMCQ?.id ?? '');
    }

    isMultiColumn(QuestionType) {
      return Boolean(QuestionType === QUESION_TYPES.MCQ.id
            || QuestionType === QUESION_TYPES.MMCQ.id);
    }

    async open(subjectId = null, iloId = null) {
      await this.loadCourseQuestions(subjectId, iloId);
      this.showDialog = true;
    }

    close() {
      this.showDialog = false;
    }

    calcAverage(question, time = false) {
      if (time) {
        return Math.round((question.questionTime / question.questions) * 100) / 100;
      }
      return Math.round((question.degrees / question.questions) * 100) / 100;
    }
}
