


































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

@Component({
  // components: { CourseList },
})
export default class RangeDifficulty extends Vue {
  @Prop() rangeDifficultyData!: number[];

  @Prop() ShowRange!: boolean;

  get rangeDifficultyX() {
    return this.rangeDifficultyData;
  }

  set rangeDifficultyX(val) {
    this.$emit('changeSlider', val);
  }

  get minDifficulty() {
    const [a] = this.rangeDifficultyData;
    return a;
  }

  get maxDifficulty() {
    const [, b] = this.rangeDifficultyData;
    return 100 - b;
  }

  get meduimDifficulty() {
    const [a, b] = this.rangeDifficultyData;
    return 100 - (a + (100 - b));
  }
}
