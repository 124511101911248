





































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import DialogComponent from '@/components/dialog.component.vue';

import {
  ExamData,
  Exam,
  ExamFilters,
  ExamAddfiltersTypes,
  ExamMatrixRecord,
} from '../../types/exam.type';
import ExamBsicdata from './ExamBasicData.component.vue';
import ExamMatrixComponent from './examMatrix.component.vue';
import { APPIDS, EXAM_GENERATION_ROUTE_NAME } from '../../enums';
import Toaster from '../../mixins/toaster.mixin';
import AddQuestions from './questionsAddEdit.component.vue';
import SelectedCourse from '../SelectedCourse.component.vue';
import SessionRefreshComponent from '../../shared/sessionRefresh.component.vue';

@Component({
  components: {
    ExamBsicdata,
    SelectedCourse,
    DialogComponent,
    ExamMatrixComponent,
    AddQuestions,
    SessionRefreshComponent,
  },
})
export default class AddExamList extends mixins(Toaster) {
  courseId: string = this.$route?.params.courseId;

  examId: string = this.$route?.params.examId;

  showBackConfirm = false;

  isSCU = false;

  showSaveConfirm = false;

  isFlexibleILOs = false;

  resErr = '';

  previousExamDegree = 0;

  previousExamQuestionCount = 0;

  examMatrixData: ExamMatrixRecord[] = [];

  submittedMatrix: unknown = {};

  BackUrl=`/exam-generation/${this.courseId}/exams-list?limit=10&offset=0`;

  examBasicData: Exam = {
    _id: '',
    course: { courseCode: '' },
    matrix: [
      {
        ilos: [],
        lectureCount: 0,
        subject: '',
      },
    ],
    isDeleted: false,
    examInfo: {
      questions: [],
      courseId: '',
      acadmicYearId: '',
      ownerId: '',
      ownerName: '',
      signature: '',
      countOfModels: 0,
      oldTotalTime: 0,
      isVerticalOrderd: false,
      examTypeName: '',
      acadmicYearName: '',
      semesterName: '',
      examLangName: '',
      shuffleName: '',
      difficultyName: '',
      difficulty: 0,
      faculty: '',
      department: '',
      examStatus: '',
      logo: '',
      examinerName: '',
      tFitness: 0,
      tQuestionsNumbers: 0,
      university: '',
      easyPercentage: 0,
      hardPercentage: 0,
      examType: '',
      examLang: '',
      semesterID: '',
      shuffle: '',
      training: '',
      examSignature: '',
      modelsMatch: '',
      examTitle: '',
      totalTime: 0,
      totalDegree: 0,
      exposureLimit: null,
      instructorId: '',
      maxDifficulty: 100,
      mediumPercentage: 0,
      minDifficulty: 1,
      questionTypesCounts: [],
      examDate: '',
      addDegree: '',
      Degree: false,
      instructorData: {},
      useQuestionsNeedUpdate: true,
    },
  };

  filtersData: ExamAddfiltersTypes = {
    courseCode: '',
    difficultyOptions: [],
    examTypeOptions: [],
    ilosCounts: [],
    langOptions: [],
    modelsMatchOptions: [],
    questionTypeOptions: [],
    questionsCount: 0,
    shuffleOptions: [],
    subjects: [],
    termOptions: [],
    turnOptions: [],
    trainingOptions: [],
    instructors: [],
    academicYears: [],
  };

  questionsError = false;

  isCourseActive = true;

  appId = APPIDS.EXAM_GENERATION;

  steps = 3;

  currentStep = 1;

  name = 'AddExam';

  examStatus: string | null = null;

  loading = true;

  $refs!: {
    ExamBsicdata: ExamBsicdata;
    ExamMatrixComponent: ExamMatrixComponent;
    addQuestionsComponent: AddQuestions;
  };

  isValidMatrix = false;

  matrixValidationChange(isValid) {
    this.isValidMatrix = isValid;
  }

  async validateExamBasicData() {
    if (this.$refs.ExamBsicdata.validate()) {
      this.currentStep = 2;
    }
  }

  async validateExamQuestionStep() {
    if (this.$refs.addQuestionsComponent.validate()) {
      this.currentStep = 3;
    }
  }

  async mounted() {
    await this.fetchFiltersData();
  }

  async fetchFiltersData() {
    try {
      if (this.examId) {
        const {
          examData,
          filters,
          CourseValidation,
        }: ExamData = await ExamGenerationProvider.getExamDataForEdit(
          this.appId,
          this.courseId,
          this.examId,
        );
        this.filtersData = filters as ExamAddfiltersTypes;
        this.examBasicData = examData?.exam as Exam;
        this.isFlexibleILOs = !!CourseValidation.isFlexibleILOs;
        this.isSCU = !!CourseValidation.isSCU;

        this.examBasicData.examInfo.acadmicYearId = this.filtersData.academicYears.some(
          (academicYear) => academicYear.baID === this.examBasicData.examInfo.acadmicYearId,
        ) ? this.examBasicData.examInfo.acadmicYearId
          : '';

        this.examStatus = this.examBasicData?.examInfo?.examStatus as string;
        if (CourseValidation) {
          this.examBasicData.examInfo.Degree = this.examBasicData.examInfo?.addDegree !== '0';
        }
        this.previousExamDegree = this.examBasicData.examInfo.totalDegree;

        this.previousExamQuestionCount = this.examBasicData.examInfo.questionTypesCounts.reduce(
          (acc, item) => acc + item.questionCount,
          0,
        );

        this.questionsError = this.filtersData.questionsCount === 0;
        this.isCourseActive = !!CourseValidation?.isCourseActive;
        this.examMatrixData = this.examBasicData.matrix;
      } else {
        const {
          filters,
          CourseValidation,
        }: ExamFilters = await ExamGenerationProvider.GetFilters(
          this.appId,
          this.courseId,
        );
        this.isFlexibleILOs = !!CourseValidation.isFlexibleILOs;
        this.isSCU = !!CourseValidation.isSCU;
        this.filtersData = filters;
        this.questionsError = this.filtersData.questionsCount === 0;
        this.isCourseActive = CourseValidation.isCourseActive;
        this.examBasicData.course.courseCode = CourseValidation.courseCode;
      }
      this.loading = false;
    } catch (err) {
      this.resErr = err;
      this.loading = false;
    }
  }

  goBack() {
    if (this.examId) {
      this.$router.push({
        name: EXAM_GENERATION_ROUTE_NAME.EXAM_DETAILS,
        params: {
          courseId: this.courseId,
          examId: this.examId,
        },
      });
    } else {
      const appFilters = this.$store.state.filters.appFilters[this.appId];
      this.$router.push({
        name: EXAM_GENERATION_ROUTE_NAME.EXAMS_LIST,
        query: appFilters,
        params: {
          courseId: this.courseId,
        },
      });
    }
  }

  SaveExamData() {
    try {
      const examBasicData = {
        examType: this.examBasicData.examInfo.examType,
        examLang: this.examBasicData.examInfo.examLang,
        term: this.examBasicData.examInfo.semesterID,
        turn: this.examBasicData.examInfo.turnID,
        shuffleOption: this.examBasicData.examInfo.shuffle,
        trainingOption: this.examBasicData.examInfo.training,
        modelsMatchOption: this.examBasicData.examInfo.modelsMatch,
        title: this.examBasicData.examInfo.examTitle,
        time: this.examBasicData.examInfo.totalTime,
        degree: this.examBasicData.examInfo.totalDegree,
        exposureLimit: this.examBasicData.examInfo.exposureLimit,
        instructor: this.examBasicData.examInfo.instructorId,
        difficultyRange: [
          this.examBasicData.examInfo.minDifficulty,
          this.examBasicData.examInfo.maxDifficulty,
        ],
        questionTypesCounts: this.examBasicData.examInfo.questionTypesCounts,
        examDate: this.examBasicData.examInfo.examDate,
        addDegree: this.examBasicData.examInfo.Degree,
        examSignature: this.examBasicData.examInfo.examSignature,
        courseCode: this.examBasicData.course.courseCode,
        acadmicYearId: this.examBasicData.examInfo.acadmicYearId,
        useQuestionsNeedUpdate: this.examBasicData.examInfo.useQuestionsNeedUpdate,
        examPeriod: this.examBasicData.examInfo.examPeriod,
      };
      if (this.isValidMatrix) {
        this.submittedMatrix = this.$refs.ExamMatrixComponent.getMatrix();
        if (this.examId) {
          this.editExamData({
            examBasicData,
            examMatrixData: this.submittedMatrix,
          });
        } else {
          this.addExamData({
            examBasicData,
            examMatrixData: this.submittedMatrix,
          });
        }
      }
    } catch (err) {
      this.resErr = err;
    }
  }

  async editExamData(sentData) {
    try {
      this.showSaveConfirm = false;
      const newExamId = await ExamGenerationProvider.updateExam(
        this.appId,
        this.courseId,
        this.$store.state.scope.selectedScopeId,
        this.examId,
        sentData,
      );
      this.$router.push({
        name: EXAM_GENERATION_ROUTE_NAME.EXAM_DETAILS,
        params: {
          courseId: this.courseId,
          examId: newExamId,
        },
      });
    } catch (err) {
      this.resErr = err;
    }
  }

  async addExamData(sentData) {
    try {
      this.showSaveConfirm = false;
      const newExamId = await ExamGenerationProvider.AddExam(
        this.appId,
        this.courseId,
        this.$store.state.scope.selectedScopeId,
        '0',
        sentData,
      );
      this.$router.push({
        name: EXAM_GENERATION_ROUTE_NAME.EXAM_DETAILS,
        params: {
          courseId: this.courseId,
          examId: newExamId,
        },
      });
    } catch (err) {
      this.resErr = err;
    }
  }

  stepStage(n) {
    if (n === 1) {
      return this.$t('labels.ExamBasicData');
    }
    if (n === 2) {
      return this.$t('labels.AddQuestions');
    }
    return this.$t('labels.ExamMatrix');
  }

  get editExam() {
    return Boolean(this.examId);
  }
}
